import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I säsongens sista avsnitt tar vi en titt på nya erbjudanden kring Linux och hårdvara. Vi snackar också om spelutvecklaren som inte får nämnas vid namn, webbläsaren Brave som har gjort ett misstag och avslutar som alltid med lite Trevliga tips på mjukvara!`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Linux + hårdvara`}<ul parentName="li">
          <li parentName="ul">{`PineTab slutsåld: `}<a parentName="li" {...{
              "href": "https://www.omgubuntu.co.uk/2020/06/pinetab-is-now-available-to-order"
            }}>{`https://www.omgubuntu.co.uk/2020/06/pinetab-is-now-available-to-order`}</a></li>
          <li parentName="ul">{`elementary OS förinstallerat: `}<a parentName="li" {...{
              "href": "https://blog.elementary.io/now-shipping-elementary-os/"
            }}>{`https://blog.elementary.io/now-shipping-elementary-os/`}</a></li>
          <li parentName="ul">{`Star Labs LabTop mk4: `}<a parentName="li" {...{
              "href": "https://blog.elementary.io/now-shipping-elementary-os/"
            }}>{`https://blog.elementary.io/now-shipping-elementary-os/`}</a></li>
          <li parentName="ul">{`System76 serval: `}<a parentName="li" {...{
              "href": "https://blog.elementary.io/now-shipping-elementary-os/"
            }}>{`https://blog.elementary.io/now-shipping-elementary-os/`}</a></li>
          <li parentName="ul">{`Dell XPS Developer Edition: `}<a parentName="li" {...{
              "href": "https://www.dell.com/en-us/work/shop/cty/pdp/spd/xps-13-9300-laptop/ctox13w10p1c2700u"
            }}>{`https://www.dell.com/en-us/work/shop/cty/pdp/spd/xps-13-9300-laptop/ctox13w10p1c2700u`}</a></li>
        </ul></li>
      <li parentName="ul">{`Total War: Troy lägger Linux-stöd på is: `}<a parentName="li" {...{
          "href": "https://www.gamingonlinux.com/2020/06/total-war-saga-troy-epic-games-store-exclusive"
        }}>{`https://www.gamingonlinux.com/2020/06/total-war-saga-troy-epic-games-store-exclusive`}</a></li>
      <li parentName="ul">{`Vi tar semester`}</li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Brave i blåsväder: `}<a parentName="li" {...{
          "href": "https://davidgerard.co.uk/blockchain/2020/06/06/the-brave-web-browser-is-hijacking-links-and-inserting-affiliate-codes/"
        }}>{`https://davidgerard.co.uk/blockchain/2020/06/06/the-brave-web-browser-is-hijacking-links-and-inserting-affiliate-codes/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`fail2ban: `}<a parentName="li" {...{
          "href": "https://github.com/fail2ban/fail2ban"
        }}>{`https://github.com/fail2ban/fail2ban`}</a></li>
      <li parentName="ul">{`uptimed / uprecords: `}<a parentName="li" {...{
          "href": "https://github.com/rpodgorny/uptimed"
        }}>{`https://github.com/rpodgorny/uptimed`}</a>{` `}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`i3, compositor, rofi, feh, polybar, plasma`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Tack Christer för Brave-nyheten`}</li>
      <li parentName="ul">{`Farhad undrar över mjukvaruutveckling vs systemutveckling`}</li>
      <li parentName="ul">{`Säsongsavslutning av TM`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      